<template>
  <v-card class="border-end shadow-none rounded-0">
    <div class="pa-5 text-center border-bottom">
      <v-avatar size="160" class="mb-5">
        <img
          :src="addResourceImage(currentUser.avatar)"
          @error="imageFallback($event)"
          class="border border-4"
        />
      </v-avatar>
      <h4 class="fs-22 fw-500 mb-1 lh-1">{{ currentUser.name }}</h4>
      <h4 v-if="showIdSwitch" class="fs-14 fw-500 mb-0 lh-1">
        ID: {{ currentUser.id }}
      </h4>
      <div
        class="swiper-container text-truncate opacity-60 d-flex align-center justify-center pl-6"
      >
        {{
          showEmail
            ? currentUser.email
              ? currentUser.email
              : currentUser.phone
            : '********'
        }}
        <img
          v-show="showEmail"
          @click="showEmail = false"
          src="@/assets/img/user-eye.png"
          width="20px"
          height="20px"
          style="margin-left: 6px"
        />
        <img
          v-show="!showEmail"
          @click="showEmail = true"
          src="@/assets/img/user-eye-close.png"
          width="20px"
          height="20px"
          style="margin-left: 6px"
        />
      </div>
    </div>

    <v-list nav class="px-0 user-side-nav">
      <UserMenu />
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import UserMenu from './UserMenu'
export default {
  components: { UserMenu },
  data: () => ({
    showEmail: true
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('app', ['showIdSwitch'])
  }
}
</script>
