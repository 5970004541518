import { render, staticRenderFns } from "./UserLayout.vue?vue&type=template&id=08a5c219&scoped=true&"
import script from "./UserLayout.vue?vue&type=script&lang=js&"
export * from "./UserLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a5c219",
  null
  
)

export default component.exports